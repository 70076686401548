(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DrugHistoryController', DrugHistoryController);

    DrugHistoryController.$inject = ['$uibModalInstance', 'chart', 'Drug', '$q', 'DTColumnBuilder', 'DTOptionsBuilder',
        '$compile', '$scope', '$rootScope', '$uibModal', 'ConfirmationService'];

    function DrugHistoryController($uibModalInstance, chart, Drug, $q, DTColumnBuilder, DTOptionsBuilder,
                                   $compile, $scope, $rootScope, $uibModal, ConfirmationService) {
        var vm = this;

        vm.drugs = [];
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.dtInstance = {};
        vm.chart = chart;

        vm.clear = clear;
        vm.search = search;
        vm.edit = edit;
        vm.del = del;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Drug.findAllByChart(
                {
                    id: vm.chart.id,
                    page: vm.page - 1,
                    size: vm.itemsPerPage
                }, function (result, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.drugs = result;
                    defer.resolve(vm.drugs);
                });
            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('').notVisible().renderWith(function (data, type, full) {
                return data ? moment(data.date).format("YYYYMMDD") : "";
            }),
            DTColumnBuilder.newColumn('date').withTitle('Date').renderWith(function (data, type, full) {
                return data ? moment(data).format($rootScope.amDateFormat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barbiturate').renderWith(function (data, type, full) {
                return resolveValueField(data.barbiturate);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Amphetamine').renderWith(function (data, type, full) {
                return resolveValueField(data.amphetamine);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Methadone').renderWith(function (data, type, full) {
                return resolveValueField(data.methadone);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Opiates').renderWith(function (data, type, full) {
                return resolveValueField(data.opiates);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Tetrahydrocannabinol').renderWith(function (data, type, full) {
                return resolveValueField(data.tetrahydrocannabinol);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Phencyclidine').renderWith(function (data, type, full) {
                return resolveValueField(data.phencyclidine);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Benzodiazepine').renderWith(function (data, type, full) {
                return resolveValueField(data.benzodiazepine);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Oxycodone').renderWith(function (data, type, full) {
                return resolveValueField(data.oxycodone);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Methamphetamine').renderWith(function (data, type, full) {
                return resolveValueField(data.methamphetamine);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Tricyclic Antidepressants').renderWith(function (data, type, full) {
                return resolveValueField(data.tricyclicAntidepressants);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Buprenorphine').renderWith(function (data, type, full) {
                return resolveValueField(data.buprenorphine);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Cocaine').renderWith(function (data, type, full) {
                return resolveValueField(data.cocaine);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Morphine').renderWith(function (data, type, full) {
                return resolveValueField(data.morphine);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Methylenedioxymethamphetamine').renderWith(function (data, type, full) {
                return resolveValueField(data.methylenedioxymethamphetamine);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.description ? data.description : "-";
            }),
            DTColumnBuilder.newColumn('createdBy').withTitle('Created by'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="dt-btn warning" ng-click="vm.edit(' + data.id + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            stButtons += '<a class="dt-btn danger" ng-click="vm.del(' + data.id + ')">' +
                '   <i class="fa fa-trash"></i></a>';

            return stButtons;
        }


        function search() {
            vm.dtInstance.reloadData();
        }

        function resolveValueField(value) {
            return value == 1 ? 'Positive' : value == -1 ? 'Negative' : 'N/A'
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/drug/update/drug-dialog.html',
                controller: 'DrugDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    entity: ['Drug', function (Drug) {
                        return Drug.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                search();
            }, function () {
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Drug').result.then(function () {
                Drug.delete({id: id}, function () {
                    search();
                });
            });
        }
    }
})();

