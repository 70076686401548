(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$rootScope', '$scope', '$state', 'Auth', 'Principal', 'ProfileService', 'previousState',
        'LoginService', 'Facility', 'CoreService', 'ACTIONS', 'employee', 'ROLES', 'Corporation', 'RESOURCES',
        'EmployeeMenu'];

    function SidebarController($rootScope, $scope, $state, Auth, Principal, ProfileService, previousState,
                               LoginService, Facility, CoreService, ACTIONS, employee, ROLES, Corporation, RESOURCES,
                               EmployeeMenu) {

        var vm = this;

        $rootScope.background = '';

        vm.isNavbarCollapsed = true;
        vm.isControlbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.$state = $state;
        vm.employee = employee;
        vm.corporations = [];
        vm.facilities = [];
        vm.sideBarItems = [];
        vm.loading = true;
        vm.access = [];
        vm.currentFacility = CoreService.getCurrentFacility();
        vm.showSidebarForms = false;

        EmployeeMenu.get();

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.changeFacility = changeFacility;
        vm.deployFacilities = deployFacilities;
        vm.showSidebarItem = showSidebarItem;
        vm.selectCorporation = selectCorporation;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        function initMenu(access) {
            vm.sideBarItems = [];

            if (vm.currentFacility) {
                vm.sideBarItems.push(...[
                    {
                        id: 'home',
                        open: 'menu-open',
                        displayName: 'Dashboard',
                        icon: 'fa fa-dashboard',
                        state: !vm.currentFacility ? 'hidden' : '',
                        style: 'display: block;',
                        url: 'dashboard',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_DASHBOARD, access), /*All Access*/
                        items: [],
                    },
                    {
                        id: 'home',
                        open: 'menu-open',
                        displayName: 'BHT Dashboard',
                        icon: 'fa fa-dashboard',
                        state: !vm.currentFacility ? 'hidden' : '',
                        style: 'display: block;',
                        url: 'bht-dashboard',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_DASHBOARD, access), /*All Access*/
                        items: [],
                    },
                    {
                        id: 'all-patient',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Patients',
                        url: 'charts',
                        icon: 'fa fa-users',
                        style: 'display: none;',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_PATIENT, access),
                        items: [],
                        function: clearChartFilter
                    },
                    {
                        id: 'blue-alerts',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Blue Alerts',
                        url: 'blue-alerts',
                        icon: 'fa fa-bell',
                        style: 'display: none;',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_PATIENT, access),
                        items: [],
                        function: clearChartFilter
                    },
                    {
                        id: 'bedSheet',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Bed Sheets',
                        icon: 'fa fa-bed',
                        style: 'display: none;',
                        url: 'bed-sheet',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_BED_SHEET, access),
                        items: [],
                        function: clearBedSheetFilter
                    },
                    {
                        id: 'incident-report',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Incident Reports',
                        icon: 'fa fa-file',
                        style: 'display: none;',
                        url: 'incident-report',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS, access),
                        items: []
                    },
                    {
                        id: 'telehealth',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Telehealth',
                        icon: 'fa fa-video-camera',
                        style: 'display: none;',
                        url: 'telehealth',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TELEHEALTH, access),
                        items: []
                    },
                    {
                        id: 'outreach-campaign',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Outreach Campaigns',
                        icon: 'fa fa-tasks',
                        style: 'display: none;',
                        url: 'outreach-campaign',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_OUTREACH_CAMPAIGNS, access),
                        items: [],
                        function: clearCampaignOutreachFilter
                    },
                    {
                        id: 'groupSession',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Group Sessions',
                        icon: 'fa fa-calendar',
                        style: 'display: none;',
                        url: 'group-session',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_GROUP_SESSIONS, access),
                        items: []
                    },
                    {
                        id: 'medicalNursing',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Medical & Nursing',
                        icon: 'fa fa-user-md',
                        style: 'display: none;',
                        url: null,
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_MEDICAL_NURSING, access),
                        items: [
                            {
                                state: '',
                                displayName: 'Lab Requisitions',
                                url: 'lab-requisition',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_MEDICAL_NURSING_LAB_REQUISITIONS, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Lab Results',
                                url: 'patient-result',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_MEDICAL_NURSING_LAB_RESULTS, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Sent Lab Results',
                                url: 'sent-lab-result',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Fax Logs',
                                url: 'patient-medication-fax-log',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_MEDICAL_NURSING_FAX, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'MARs/MORs',
                                url: 'patient-medication-mars',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_MEDICAL_NURSING_MARS, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Physician Review',
                                url: 'physician-review',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_MEDICAL_NURSING_PHYSICIAN_REVIEW, access),
                                enable: vm.currentFacility != null
                            }
                        ]
                    },
                    {
                        id: 'shift',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Shifts',
                        url: 'shift',
                        icon: 'fa fa-clock-o',
                        style: 'display: none;',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_SHIFT, access),
                        items: []
                    },
                    {
                        id: 'contacts',
                        open: '',
                        displayName: 'Contacts',
                        icon: 'fa fa-phone',
                        state: !vm.currentFacility ? 'hidden' : '',
                        style: 'display: none;',
                        url: 'contacts-facility',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CONTACTS_FACILITY, access),
                        items: []
                    },
                    {
                        id: 'report',
                        state: '',
                        open: '',
                        displayName: 'Reports',
                        url: 'reports',
                        icon: 'fa fa-file-text-o',
                        style: 'display: none;',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_REPORTS, access),
                        items: []
                    },
                    {
                        id: 'facility-billing',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Facility Billing',
                        url: 'facility-billing',
                        icon: 'fa fa-dollar',
                        style: 'display: none;',
                        access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Billing',
                                url: 'facility-billing',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Billing Config',
                                url: 'facility-billing-config',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                                enable: vm.currentFacility != null
                            },
                        ]
                    },
                    {
                        id: 'audit',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Billing Audit',
                        url: 'audit',
                        icon: 'fa fa-dollar',
                        style: 'display: none;',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_AUDIT, access),
                        items: []
                    },
                    {
                        id: 'pdf-chart-report',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Storage',
                        url: 'pdf-chart-report',
                        icon: 'fa fa-pie-chart',
                        style: 'display: none;',
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_REPORTS, access),
                        items: []
                    },
                    {
                        id: 'templates',
                        state: !vm.currentFacility ? 'hidden' : '',
                        open: '',
                        displayName: 'Templates',
                        icon: 'fa fa-file-pdf-o',
                        style: 'display: none;',
                        url: null,
                        access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES, access),
                        items: [
                            {
                                state: '',
                                displayName: 'Care & Delivery',
                                url: 'evaluation-template',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Care & Delivery Audit',
                                url: 'evaluation-audit',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_EVALUATION_AUDIT, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Care & Delivery MARs/MORs',
                                url: 'evaluation-template-mars-mors',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_EVALUATION_TEMPLATE_MARS_MORS, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Group Session Form Tpl',
                                url: 'group-session-form-tpl',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Group Sessions',
                                url: 'group-session-template',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Assistant',
                                url: 'assistant',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_ASSISTANT, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Care Coordination',
                                url: 'care-coordination-template',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_COORDINATION, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Protocol',
                                url: 'protocol',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_PATIENT_MEDICATION_TEMPLATE, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Building',
                                url: 'building',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_BUILDING, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Room',
                                url: 'room',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_ROOM, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Bed',
                                url: 'bed',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_BED, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Incident Report Note Template',
                                url: 'incident-report-note-template',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_INCIDENT_REPORT_NOTE_TEMPLATE, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Campaign',
                                url: 'campaign',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CAMPAIGN, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Facesheet',
                                url: 'facesheet-tpl',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_FACESHEET_TEMPLATE, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Facesheet Section',
                                url: 'facesheet-section',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_FACESHEET_SECTION, access),
                                enable: vm.currentFacility != null
                            },
                            {
                                state: '',
                                displayName: 'Track',
                                url: 'track',
                                icon: 'fa fa-circle-o',
                                access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_TRACK, access),
                                enable: vm.currentFacility != null
                            }
                        ]
                    },
                    // {
                    //     id: 'crm',
                    //     state: '',
                    //     open: '',
                    //     displayName: 'CRM',
                    //     url: null,
                    //     icon: 'fa fa-cog',
                    //     style: 'display: none;',
                    //     access: [ROLES.ROLE_SYSTEM_ADMIN].join(),
                    //     items: [
                    //         {
                    //             state: '',
                    //             displayName: 'Opportunity',
                    //             url: 'crm-opportunity',
                    //             icon: 'fa fa-circle-o',
                    //             access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY, access),
                    //             enable: true
                    //         },
                    //         {
                    //             state: '',
                    //             displayName: 'Campaign',
                    //             url: 'crm-campaign',
                    //             icon: 'fa fa-circle-o',
                    //             access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_CAMPAIGN, access),
                    //             enable: true
                    //         },
                    //         {
                    //             state: '',
                    //             displayName: 'Opportunity Status',
                    //             url: 'crm-opportunity-status',
                    //             icon: 'fa fa-circle-o',
                    //             access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY_STATUS, access),
                    //             enable: true
                    //         },
                    //         {
                    //             state: '',
                    //             displayName: 'Outbound Referral Status',
                    //             url: 'crm-outbound-referral-status',
                    //             icon: 'fa fa-circle-o',
                    //             access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OUTBOUND_REFERRAL_STATUS, access),
                    //             enable: true
                    //         },
                    //         {
                    //             state: '',
                    //             displayName: 'Referral Source',
                    //             url: 'crm-referral-source',
                    //             icon: 'fa fa-circle-o',
                    //             access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_SOURCE, access),
                    //             enable: vm.currentFacility != null
                    //         },
                    //         {
                    //             state: '',
                    //             displayName: 'Referral Source Contacts',
                    //             url: 'crm-referral-contact',
                    //             icon: 'fa fa-circle-o',
                    //             access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_SOURCE, access),
                    //             enable: true
                    //         },
                    //         {
                    //             state: '',
                    //             displayName: 'Tags',
                    //             url: 'crm-tag',
                    //             icon: 'fa fa-circle-o',
                    //             access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_TAG, access),
                    //             enable: true
                    //         }
                    //     ]
                    // },
                ]);
            }

            if (vm.currentFacility && vm.currentFacility.facilityType === 'HIV') {
                vm.sideBarItems.push({
                    id: '304B-program-eligibility',
                    open: '',
                    displayName: '304B Program Eligibility',
                    icon: 'fa fa-cog',
                    style: 'display: none;',
                    url: null,
                    access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_304B_PROGRAM_ELIGIBILITY, access),
                    items: [
                        {
                            state: '',
                            displayName: 'Corporation Program Eligibility',
                            url: 'prog-elig-corp',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CORPORATION_PROGRAM_ELIGIBILITY, access),
                            enable: vm.currentFacility.facilityType === 'HIV'
                        },
                        {
                            state: '',
                            displayName: 'Facility Program Eligibility',
                            url: 'prog-elig-facility',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_FACILITY_PROGRAM_ELIGIBILITY, access),
                            enable: vm.currentFacility.facilityType === 'HIV'
                        },
                        {
                            state: '',
                            displayName: 'Medication Program Eligibility',
                            url: 'prog-medication',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_MEDICATION_PROGRAM_ELIGIBILITY, access),
                            enable: vm.currentFacility.facilityType === 'HIV'
                        },
                        {
                            state: '',
                            displayName: 'Bin',
                            url: 'bin',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_BIN, access.data),
                            enable: vm.currentFacility.facilityType === 'HIV'
                        },
                        {
                            state: '',
                            displayName: 'Employee Program Eligibility',
                            url: 'prog-elig-employee',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE_PROGRAM_ELIGIBILITY, access),
                            enable: vm.currentFacility.facilityType === 'HIV'
                        }
                    ]
                })
            }

            getLaboratoriesMenu(access);

            getConfig(access);

            if (vm.currentFacility && vm.currentFacility.facilityType === 'COVID_RC') {
                getCovidMenu(access);
            }

            getAdminMenu(access);

            getDrFirstConfig(access);

            sortSubMenus();

            return vm.sideBarItems;
        }

        $rootScope.$on('$stateChangeSuccess', function () {
            selectMenuItem($state.current.name);
        });

        init();

        function init() {
            getAdminMenu();

            Corporation.query(function (result) {
                if (result) {
                    vm.corporations = result;

                    if (vm.corporations.length == 1) {
                        vm.corporation = vm.corporations[0];

                        selectCorporation();
                    } else {
                        var storedCorporation = CoreService.getCorporation();

                        if (storedCorporation) {
                            //Check if the current corporation is in the list
                            var current = $.grep(vm.corporations, function (corporation, i) {
                                return corporation.id == storedCorporation.id;
                            })[0];

                            //user last facility access
                            if (current) {
                                //update current corporation
                                vm.corporation = current;

                                selectCorporation();

                            } else {
                                CoreService.setCorporation(null);
                            }
                        }
                    }
                }
            });
        }

        $rootScope.$on('$stateChangeSuccess', function () {
            selectMenuItem($state.current.name);
        });

        selectMenuItem($state.current.name);

        function selectMenuItem(url) {
            var lessChild = null;
            var parent = null;
            var child = null;
            for (var i = 0; i < vm.sideBarItems.length; i++) {
                for (var j = 0; j < vm.sideBarItems[i].items.length; j++) {
                    if (vm.sideBarItems[i].items[j].items == null) {
                        if (vm.sideBarItems[i].items[j].url == url) {
                            parent = i;
                            child = j;
                        } else {
                            vm.sideBarItems[i].open = '';
                            vm.sideBarItems[i].style = 'display: none;';
                            vm.sideBarItems[i].state = '';
                            vm.sideBarItems[i].items[j].state = '';
                            vm.sideBarItems[i].items[j].icon = "fa fa-circle-o";
                            vm.sideBarItems[i].items[j].active = '';
                        }
                    } else {
                        for (var k = 0; k < vm.sideBarItems[i].items[j].items.length; k++) {
                            if (vm.sideBarItems[i].items[j].items[k].url == url) {
                                parent = i;
                                child = j;
                                lessChild = k;
                            } else {
                                vm.sideBarItems[i].open = '';
                                vm.sideBarItems[i].style = 'display: none;';
                                vm.sideBarItems[i].state = '';
                                vm.sideBarItems[i].items[j].open = '';
                                vm.sideBarItems[i].items[j].style = 'display: none;';
                                vm.sideBarItems[i].items[j].state = '';
                                vm.sideBarItems[i].items[j].items[k].state = '';
                                vm.sideBarItems[i].items[j].items[k].icon = "fa fa-circle-o";
                                vm.sideBarItems[i].items[j].items[k].active = '';
                            }
                        }
                    }
                }
            }

            if (parent != null && child != null) {
                if (lessChild == null) {
                    vm.sideBarItems[parent].open = 'menu-open';
                    vm.sideBarItems[parent].style = 'display: block;';
                    vm.sideBarItems[parent].state = 'active';

                    vm.sideBarItems[parent].items[child].state = 'active';
                    vm.sideBarItems[parent].items[child].icon = "fa fa-dot-circle-o";
                    vm.sideBarItems[parent].items[child].active = 'active';
                } else {
                    vm.sideBarItems[parent].open = 'menu-open';
                    vm.sideBarItems[parent].style = 'display: block;';
                    vm.sideBarItems[parent].state = 'active';

                    vm.sideBarItems[parent].items[child].open = 'menu-open';
                    vm.sideBarItems[parent].items[child].style = "display: block;";
                    vm.sideBarItems[parent].items[child].state = 'active';

                    vm.sideBarItems[parent].items[child].items[lessChild].state = 'active';
                    vm.sideBarItems[parent].items[child].items[lessChild].icon = "fa fa-dot-circle-o";
                    vm.sideBarItems[parent].items[child].items[lessChild].active = 'active';
                }
            }
        }

        function showSidebarItem() {
            angular.element('.treeview > a').each(function () {
                var t = $(this);
                if (t.hasClass('hidden')) {
                    t.parent().addClass('hidden-item');
                } else {
                    t.parent().addClass('visible-item');
                }
            });
        }

        $rootScope.$on('bluebookApp:updateFacilities', function (event, result) {
            for (var i = 0; i < vm.facilities.length && vm.facilities[i].id != result.id; i++) ;
            if (i == vm.facilities.length) {
                vm.facilities.push(result);
            } else {
                vm.facilities[i] = result;
            }
        });

        $rootScope.$on('bluebookApp:deleteFacilities', function (event, result) {
            for (var i = 0; i < vm.facilities.length && vm.facilities[i].id != result; i++) ;
            vm.facilities.splice(i, 1);
        });

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function deployFacilities(result) {
            vm.facilities = result;
            var force = false;

            if (vm.facilities.length > 0) {
                vm.currentFacility = CoreService.getCurrentFacility();

                if (vm.currentFacility) {
                    //Check if the current facility is in the list
                    var current = $.grep(vm.facilities, function (facility, i) {
                        return facility.id == vm.currentFacility.id;
                    })[0];

                    //user lost facility access
                    if (current) {
                        //update current facility
                        vm.currentFacility = current;

                        initMenu(vm.access);
                    } else {
                        vm.currentFacility = result[0];
                        force = true;

                        initMenu(vm.access);
                    }
                } else {
                    force = true;
                    vm.currentFacility = result[0];

                    initMenu(vm.access);
                }

                CoreService.setCurrentFacility(vm.currentFacility);

                if (previousState.name == 'login' || force) {
                    if ($state.current.name == 'dashboard') {
                        $state.reload('dashboard');
                    } else {
                        $state.go('dashboard');
                    }
                }
            } else {
                vm.currentFacility = null;
                CoreService.setCurrentFacility(null);

                $state.go('dashboard-empty');

                initMenu(vm.access);
            }
        }

        function changeFacility() {
            CoreService.setCurrentFacility(vm.currentFacility);

            initMenu(vm.access);
            if ($state.current.name == 'dashboard') {
                $state.reload('dashboard');
            } else {
                $state.go('dashboard');
            }
        }

        function sortSubMenus() {
            _.forEach(vm.sideBarItems, function (parentItem) {
                if (parentItem.items && parentItem.items.length > 0) {
                    parentItem.items = _.sortBy(parentItem.items, 'displayName');
                }
            });
        }

        vm.clearChartFilter = clearChartFilter;
        vm.clearBedSheetFilter = clearBedSheetFilter;

        function clearChartFilter() {
            CoreService.setChartFilter(null);
        }

        function clearBedSheetFilter() {
            CoreService.setStorage('filterBedSheet', CoreService.initBedSheetFilter());
        }

        function clearCampaignOutreachFilter() {
            CoreService.setStorage('filterCampaignOutreach', CoreService.initCampaignOutreachFilter());
        }

        function selectCorporation() {
            CoreService.setCorporation(vm.corporation);
            vm.sideBarItems = [];

            Auth.loadResourceAccess(RESOURCES.RESOURCE_MAIN_MENU, vm.corporation).then(function (access) {
                vm.access = access.data;

                Facility.byCorporation({id: vm.corporation.id}, function (result) {
                    deployFacilities(result);
                });
            });
        }

        function getConfig(access) {
            vm.sideBarItems.push(
                {
                    id: 'config',
                    state: '',
                    open: '',
                    displayName: 'Configuration',
                    url: null,
                    icon: 'fa fa-cog',
                    style: 'display: none;',
                    access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG, access),
                    enable: true,
                    items: [
                        {
                            state: '',
                            displayName: 'Via',
                            url: 'via',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_VIA, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Business Credential',
                            url: 'business-credential',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_BUSINESS_CREDENTIAL, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Race',
                            url: 'type-race',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_RACE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Discharge Type',
                            url: 'type-discharge-type',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_DISCHARGE_TYPE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Marital Status',
                            url: 'type-marital-status',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_MARITAL_STATUS, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Roles',
                            url: 'role',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MANAGE_ROLE_RESOURCE_AND_ACTIONS, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Insurance Audit Config',
                            url: 'insurance-billing-config',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_INSURANCE_AUDIT_CONFIG, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Insurance Types',
                            url: 'insurance-type',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_INSURANCE_TYPE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Insurance Relation',
                            url: 'insurance-relation',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_INSURANCE_RELATION_CONFIG, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Doses Form',
                            url: 'dose-form',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_DOSE_FORM, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Employee',
                            url: 'employee',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Concurrent Review Frequency',
                            url: 'concurrent-review-frequency',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CONCURRENT_REVIEW_FREQUENCY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Severity',
                            url: 'severity',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_SEVERITY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Reaction',
                            url: 'reaction',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_REACTION, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Allergen',
                            url: 'allergen',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_ALLERGEN, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Acuity',
                            url: 'acuity',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_ACUITY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Type of Check',
                            url: 'type-check',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_OF_CHECK, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Contact Facility Type',
                            url: 'type-contact-facility-type',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_CONTACT_FACILITY_TYPE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Ethnicity Types',
                            url: 'type-ethnicity',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_OF_ETHNICITY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Session Type',
                            url: 'session-type',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_GROUP_SESSION_FREQUENCY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Level Of Care',
                            url: 'type-level-care',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LEVEL_OF_CARE, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Patient Process',
                            url: 'type-patient-process',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_PATIENT_PROCESS, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Shift Type',
                            url: 'shift-type',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_SHIFT_TYPE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Rationale',
                            url: 'rationale',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_RATIONALE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Route',
                            url: 'route',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_ROUTE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Protocol Group',
                            url: 'protocol-group',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_PROTOCOL_GROUP, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Contact Address',
                            url: 'contact-address',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CONTACT_ADDRESS, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Patient Medication Missed Reason',
                            url: 'patient-medication-take-missed-reason',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_PATIENT_MEDICATION_MISSED, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Incident Type',
                            url: 'incident-type',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_INCIDENT_TYPE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Incident Department',
                            url: 'incident-department',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_INCIDENT_DEPARTMENT, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Payment Methods',
                            url: 'type-payment-methods',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_PAYMENT_METHODS, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Patient Contact Types',
                            url: 'type-patient-contact-types',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_PATIENT_CONTACT_TYPE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Insurance Level',
                            url: 'insurance-level',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_INSURANCE_LEVEL, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Patient Contacts Relationship Types',
                            url: 'type-patient-contacts-relationship',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_PATIENT_CONTACTS_RELATIONSHIP, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Speciality Types',
                            url: 'type-speciality',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_SPECIALITY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Food Diets',
                            url: 'type-food-diets',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_FOOD_DIETS, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Glucose Intervention',
                            url: 'glucose-intervention',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_GLUCOSE_INTERVENTION, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Greeting',
                            url: 'greeting',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_GREETING, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Messages History',
                            url: 'message-history',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_MESSAGE_HISTORY, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'VerifyTX Schedule',
                            url: 'verifytx-schedule',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_VERIFYTX_SCHEDULE, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Chart Blocked',
                            icon: 'fa fa-circle-o',
                            url: 'chart-blocked',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_CHART_BLOCKED, access),
                            enable: true
                        },
                        // {
                        //     state: '',
                        //     displayName: 'Appointment Types',
                        //     url: 'appointment-type',
                        //     icon: 'fa fa-circle-o',
                        //     access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_APPOINTMENT_TYPE, access),
                        //     enable: vm.currentFacility != null
                        // },
                        // {
                        //     state: '',
                        //     displayName: 'Blocked Time Reasons',
                        //     url: 'blocked-time-reason',
                        //     icon: 'fa fa-circle-o',
                        //     access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_BLOCKED_TIME_REASON, access),
                        //     enable: vm.currentFacility != null
                        // },
                        {
                            state: '',
                            displayName: 'Auto Fax',
                            url: 'auto-fax',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_AUTO_FAX, access),
                            enable: vm.currentFacility != null
                        },
                        // {
                        //     state: '',
                        //     displayName: 'Inst Claim Conf',
                        //     url: 'inst-claim-conf',
                        //     icon: 'fa fa-circle-o',
                        //     access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_INST_CLAIM_CONF, access),
                        //     enable: vm.currentFacility != null
                        // },
                        {
                            state: '',
                            displayName: 'Facility Icd10s',
                            url: 'facility-icd-10',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_FACILITY_ICD10, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Medications',
                            url: 'medication',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_MEDICATIONS, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Incident Severity',
                            url: 'incident-severity',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_INCIDENT_SEVERITY, access),
                            enable: true
                        },
                        //  jhipster-needle-sidebar
                    ]
                }
            );
        }

        function getAdminMenu(access) {
            vm.sideBarItems.push(
                {
                    id: 'config-admins',
                    state: '',
                    open: '',
                    displayName: 'Configuration Admins',
                    url: null,
                    icon: 'fa fa-cog',
                    style: 'display: none;',
                    access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                    enable: true,
                    items: [
                        {
                            state: '',
                            displayName: 'Supervisors',
                            url: 'employee-system',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Corporation',
                            url: 'corporation',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Facility',
                            url: 'facility',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Init Values',
                            url: 'init-value',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Documo',
                            url: 'documo',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Resources',
                            url: 'resource',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Fax Number',
                            url: 'fax-number',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Facility Phone Number',
                            url: 'facility-phone-number',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Reconnect Care Credential',
                            url: 'reconnect-care-credential',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'VerifyTX Credential',
                            url: 'verifytx-credential',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Billing Schedule',
                            url: 'billing-schedule',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        }
                    ]
                }
            );
        }

        function getLaboratoriesMenu(access) {
            vm.sideBarItems.push(
                {
                    id: 'laboratories',
                    displayName: 'Laboratories',
                    icon: 'fa fa-cog',
                    access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access),
                    enable: true,
                    items: [
                        {
                            state: '',
                            displayName: 'Laboratory',
                            url: 'laboratory',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Lab Integration',
                            url: 'facility-lab',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Lab Compendium',
                            url: 'lab-compendium',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Tubes',
                            url: 'tube',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Specimens',
                            url: 'specimen',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Compendium Report Group',
                            url: 'compendium-report-group',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Lab Compendium Restriction',
                            url: 'lab-compendium-restriction',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Lab Requisition Consents',
                            url: 'lab-requisition-consent',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Send Lab Compendium',
                            url: 'send-lab-compendium',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Abnormal Alert',
                            url: 'abnormal-alert',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access),
                            enable: vm.currentFacility != null
                        },
                    ]
                }
            )
        }

        function getCovidMenu(access) {
            vm.sideBarItems.push(
                {
                    id: 'covid',
                    displayName: 'COVID RC',
                    icon: 'fa fa-cog',
                    access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG, access),
                    enable: true,
                    items: [
                        {
                            state: '',
                            displayName: 'Ports',
                            url: 'port',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_PORT, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Vessels',
                            url: 'vessel',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_VESSEL, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'RC Upload',
                            url: 'rc-upload',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_RC_UPLOAD_CHART, access),
                            enable: vm.currentFacility != null
                        },
                        {
                            state: '',
                            displayName: 'Covid Physician',
                            url: 'covid-physician',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_PHYSICIAN, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Covid Icd 10',
                            url: 'covid-icd-10',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_ICD10, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Covid Question',
                            url: 'covid-question',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_QUESTION, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Covid Facility',
                            url: 'covid-facility',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_FACILITY, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Covid Lab Integration',
                            url: 'covid-facility-lab',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_FACILITY_LAB, access),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Covid Lab Compendium',
                            url: 'covid-lab-compendium',
                            icon: 'fa fa-circle-o',
                            access: Auth.resourceAuthorize(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_LAB_COMPENDIUM, access),
                            enable: true
                        }
                    ]
                }
            )
        }

        function getDrFirstConfig(access) {
            vm.sideBarItems.push(
                {
                    id: 'config',
                    state: '',
                    open: '',
                    displayName: 'DrFirst',
                    url: null,
                    icon: 'fa fa-cog',
                    style: 'display: none;',
                    access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                    enable: true,
                    items: [
                        {
                            state: '',
                            displayName: 'Practice Info',
                            url: 'drf-practice-info',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                        {
                            state: '',
                            displayName: 'Accounts',
                            url: 'drf-account',
                            icon: 'fa fa-circle-o',
                            access: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM].join(),
                            enable: true
                        },
                    ]
                }
            );
        }
    }
})();
