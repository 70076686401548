(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LaboratoryController', LaboratoryController);

    LaboratoryController.$inject = ['GenericEntityDatatableService', 'Laboratory', 'CoreService', '$q', '$uibModal',
        'GUIUtils', 'ConfirmationService'];

    function LaboratoryController(GenericEntityDatatableService, Laboratory, CoreService, $q, $uibModal,
                                  GUIUtils, ConfirmationService) {
        var vm = this;

        var params = {
            corporationId: CoreService.getCorporation().id
        }

        vm.descriptor = {
            title: 'Laboratories',
            newButtonTitle: 'New Laboratory',
            entityClassHumanized: 'Laboratory',
            entityStateName: 'laboratory',
            service: Laboratory,
            serviceMethod: 'filter',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    title: 'Active',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.active);
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.addLogoAction(), {
                    name: "Add Logo",
                    action: function (data) {
                        return addLogoLaboratory(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Laboratory',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/laboratory/update/laboratory-dialog.html',
                controller: 'LaboratoryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            address_1: null,
                            address_2: null,
                            zip: null,
                            city: null,
                            signedOrderRequired: false,
                            fax: null,
                            email: null,
                            phone: null,
                            countryState: null,
                            corporation: CoreService.getCorporation(),
                            sftpHost: "sftp.bluebookehr.com",
                            sftpPassword: null,
                            path: 'www/',
                            ordersPath: 'orders/',
                            resultsPath: 'results/',
                            active: true,
                            clia: null
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/laboratory/update/laboratory-dialog.html',
                controller: 'LaboratoryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Laboratory', function (Laboratory) {
                        return Laboratory.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Laboratory').result.then(function () {
                    Laboratory.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }

        function addLogoLaboratory(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/laboratory/logo/laboratory-logo-dialog.html',
                    controller: 'LaboratoryLogoDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['Laboratory', function (Laboratory) {
                            return Laboratory.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }
    }
})();
