(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('genericEntityDatatable', GenericEntityDatatable);

    GenericEntityDatatable.$inject = ['DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile', '$filter'];

    function GenericEntityDatatable(DTOptionsBuilder, DTColumnBuilder, $q, $compile, $filter) {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/components/generic-entity-datatable/generic-entity-datatable.tpl.html',
            scope: {
                descriptor: '<'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, element, attrs, ngModel, transclude) {
            $scope.dtInstanceCallback = function (instance) {
                $scope.dtInstance = instance;
            }

            $scope.get = function (sSource, aoData, fnCallback, oSettings) {
                $scope.selectAll = false;
                $scope.selected = {};

                var draw = aoData[0].value;
                // var order = aoData[2].value;
                var start = aoData[3].value;
                var length = aoData[4].value;
                // var params = {
                //     start: start,
                //     limit: length,
                // }

                $scope.itemsPerPage = length;
                $scope.page = (start / length) + 1;

                var filter = angular.copy($scope.params);
                filter.page = start / length;
                filter.size = length;

                filter = $scope.getFilter(filter);

                $scope.descriptor.service[$scope.descriptor.serviceMethod](filter, function (result, headers) {
                    $scope.totalItems = headers('X-Total-Count') ? headers('X-Total-Count') : result.length;

                    var records = {
                        'draw': 0,
                        'recordsTotal': 0,
                        'recordsFiltered': 0,
                        'data': []
                    };
                    if (result) {
                        records = {
                            'draw': draw,
                            'recordsTotal': $scope.totalItems,
                            'recordsFiltered': $scope.totalItems,
                            'data': result
                        };
                    }
                    $scope.dtInstance.total = $scope.totalItems;
                    $scope.values = result;

                    fnCallback(records);

                    if ($scope.descriptor.filterCallback) {
                        $scope.descriptor.filterCallback(angular.extend({}, filter, {totalItems: $scope.totalItems}));
                    }
                });
            }

            $scope.rowCallback = function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            }

            $scope.getLocalOptions = function () {
                return DTOptionsBuilder
                    .fromFnPromise(function () {
                        var defer = $q.defer();

                        if (!$scope.localSearch || !$scope.filter.searchQuery || $scope.filter.searchQuery === '') {
                            var filter = angular.copy($scope.params);
                            filter = $scope.getFilter(filter);

                            $scope.descriptor.service[$scope.descriptor.serviceMethod](filter, function (result) {
                                $scope.values = result;
                                $scope.totalItems = result.length;

                                defer.resolve(result);

                                if ($scope.descriptor.filterCallback) {
                                    $scope.descriptor.filterCallback(angular.extend({}, filter, {totalItems: $scope.values.length}));
                                }
                            });
                        } else {
                            defer.resolve($filter('filter')($scope.values, $scope.filter.searchQuery, undefined));
                        }

                        return defer.promise;
                    })
                    .withPaginationType('full_numbers')
                    .withBootstrap()
                    .withOption('paging', true)// required
                    .withOption("bPaginate", true)
                    .withOption('lengthMenu', [[5, 10, 15, 25], [5, 10, 15, 25]])
                    .withDisplayLength($scope.itemsPerPage)
                    .withLanguage({
                        "paginate": {
                            "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                            "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                            "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                            "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                        }
                    })
                    .withDOM('ltip')
                    // .withOption('aaSorting', [[2, 'desc'], [4, 'asc']])
                    .withOption('headerCallback', function (header) {
                        if (!$scope.headerCompiled) {
                            $scope.headerCompiled = true;
                            $compile(angular.element(header).contents())($scope);
                        }
                    })
                    .withOption('createdRow', function (row) {
                        $compile(angular.element(row).contents())($scope);
                    });
            }

            $scope.getRemoteOptions = function () {
                return DTOptionsBuilder
                    .newOptions()
                    .withPaginationType('full_numbers')
                    .withBootstrap()
                    .withFnServerData($scope.get)
                    .withDataProp('data')// parameter name of list use in getLeads Fuction
                    .withOption('processing', true) // required
                    .withOption('serverSide', true)// required
                    .withOption('paging', true)// required
                    .withOption("bPaginate", true)
                    .withOption('lengthMenu', [[5, 10, 15, 25], [5, 10, 15, 25]])
                    .withDisplayLength($scope.itemsPerPage)
                    .withLanguage({
                        "paginate": {
                            "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                            "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                            "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                            "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                        }
                    })
                    .withDOM('ltip')
                    .withOption('headerCallback', function (header) {
                        if (!$scope.headerCompiled) {
                            $scope.headerCompiled = true;
                            $compile(angular.element(header).contents())($scope);
                        }
                    })
                    .withOption('createdRow', function (row) {
                        $compile(angular.element(row).contents())($scope);
                    });
            }

            $scope.getDtColumns = function () {
                var dtColumns = [];

                if ($scope.withSelectionColumn) {
                    var dtColumn = DTColumnBuilder
                        .newColumn(null)
                        .withTitle('<input type="checkbox" ' +
                            'ng-model="selectAll" ' +
                            'ng-change="toggleAll()" ' +
                            'ng-disabled="descriptor.disableAllSelectionColumn && descriptor.disableAllSelectionColumn(filter)">')

                        .withOption('width', '25px')
                        .notSortable()
                        .renderWith(function (data, type, full, meta) {
                            if ($scope.selected[full[$scope.selectionColumn]] === undefined) {
                                $scope.selected[full[$scope.selectionColumn]] = false;
                            }

                            return '<input type="checkbox" ' +
                                'ng-model="selected[' + data[$scope.selectionColumn] + ']" ' +
                                'ng-click="toggleOne()" ' +
                                'ng-disabled="descriptor.disableSelectionColumn && descriptor.disableSelectionColumn(filter)">';
                        });

                    if ($scope.descriptor.isVisibleSelectionColumn && !$scope.descriptor.isVisibleSelectionColumn($scope.filter)) {
                        dtColumn = dtColumn.notVisible();
                    }

                    dtColumns.push(
                        dtColumn
                    );
                }

                dtColumns.push(...
                    _.map($scope.descriptor.columns, function (column) {
                        var dtColumn;
                        if (column.render === undefined) {

                            dtColumn = DTColumnBuilder.newColumn(column.name).withTitle(column.title).notSortable();
                        } else {

                            dtColumn = DTColumnBuilder.newColumn(null).withTitle(column.title).notSortable().renderWith(column.render);
                        }

                        if (column.isVisible && !column.isVisible()) {
                            dtColumn = dtColumn.notVisible();
                        }

                        _.forEach(column.options, function (option) {
                            dtColumn = dtColumn.withOption(option.key, option.value);
                        });

                        return dtColumn;
                    })
                );

                return dtColumns;
            }

            $scope.updateDtColumns = function () {
                if ($scope.descriptor.updateColumns) {
                    $scope.descriptor.updateColumns($scope.dtColumns, $scope.filter);
                }
            }

            $scope.getDtColumnRowActions = function () {
                if ($scope.descriptor.rowActions && $scope.descriptor.rowActions.length > 0) {
                    _.forEach($scope.descriptor.rowActions, function (action, i) {
                        action.index = i;
                    });

                    return [
                        DTColumnBuilder
                            .newColumn(null).withTitle('Actions')
                            .withOption('width', '50px')
                            .notSortable()
                            .renderWith(function (data, type, full, meta) {
                                return '<div class="btn-group dd-buttons" uib-dropdown dropdown-append-to-body>' +
                                    '   <button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                                    '       <i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                                    '   </button>' +
                                    '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                                    _.join(
                                        _.map(
                                            _.filter($scope.descriptor.rowActions, function (action) {
                                                return action.ngIf === undefined || action.ngIf(data, $scope.filter);
                                            }),
                                            function (action) {
                                                return '' +
                                                    '<li role="menuitem">' +
                                                    '   <a href="" class="' + action.aClass + '" ng-click="executeRowAction(' + action.index + ', ' + data[$scope.selectionColumn] + ')">' +
                                                    '       <i class="' + action.iClass + '" aria-hidden="true"></i>' + action.name +
                                                    '   </a>' +
                                                    '</li>'
                                            }), "") +
                                    '</ul>' +
                                    '</div>';
                            })
                    ]
                }

                return [];
            }

            $scope.getDtOptions = function () {
                $scope.pagination = !$scope.descriptor.pagination || $scope.descriptor.pagination === 'local' ? 'local' : 'remote';

                var dtOptions = $scope.pagination === 'local' ? $scope.getLocalOptions() : $scope.getRemoteOptions();

                _.forEach($scope.descriptor.options, function (option) {
                    dtOptions = dtOptions.withOption(option.key, option.value);
                })

                return dtOptions;
            }

            $scope.executeRowAction = function (index, id) {
                var action = _.find($scope.descriptor.rowActions, ['index', index]);
                var isPromise = action.action(_.find($scope.values, [$scope.selectionColumn, id]));

                if (isPromise == null) {
                    if (action.reload) {
                        $scope.search();
                    }
                } else {
                    isPromise.then(
                        function () {
                            $scope.search();
                        },
                        function () {
                            $scope.search();
                        }
                    );
                }
            }

            $scope.search = function () {
                $scope.page = 1;
                $scope.selectAll = false;
                $scope.selected = {};

                if ($scope.pagination === 'local') {
                    $scope.dtInstance.reloadData();
                } else {
                    $scope.dtInstance.DataTable.page($scope.page - 1).draw('page');
                }
            }

            $scope.clearFilter = function () {
                $scope.filter = $scope.initFilter();
                $scope._filter = angular.copy($scope.filter);

                $scope.search();
            }

            $scope.executeAction = function (action) {
                var isPromise = action.action($scope.filter, $scope.getSelected());

                if (isPromise == null) {
                    if (action.reload !== false) {
                        $scope.updateDtColumns();
                        $scope.search();
                    }
                } else {
                    isPromise.then(
                        function () {
                            $scope.updateDtColumns();
                            $scope.search();
                        },
                        function () {
                            $scope.updateDtColumns();
                            $scope.search();
                        }
                    );
                }
            }

            $scope.initFilter = function () {
                var filter = {
                    searchQuery: ""
                };

                _.forEach($scope.filterDescriptors, function (filterDescriptor) {
                    if (filterDescriptor.defaultValue) {
                        filter[filterDescriptor.name] = filterDescriptor.defaultValue();
                    } else {
                        filter[filterDescriptor.name] = null;
                    }
                })

                return filter;
            }

            $scope.getFilter = function (filter) {
                filter.searchQuery = $scope.filter.searchQuery;

                _.forEach($scope.filterDescriptors, function (filterDescriptor) {
                    if (filterDescriptor.read) {
                        filter[filterDescriptor.name] = filterDescriptor.read($scope.filter);
                    } else {
                        filter[filterDescriptor.name] = $scope.filter[filterDescriptor.name];
                    }
                });

                return filter;
            }

            $scope.toggleAll = function () {
                for (var id in $scope.selected) {
                    if ($scope.selected.hasOwnProperty(id)) {
                        $scope.selected[id] = $scope.selectAll;
                    }
                }
            }

            $scope.toggleOne = function () {
                for (var id in $scope.selected) {
                    if ($scope.selected.hasOwnProperty(id)) {
                        if (!$scope.selected[id]) {
                            $scope.selectAll = false;
                            return;
                        }
                    }
                }
                $scope.selectAll = true;
            }

            $scope.getSelected = function () {
                var ids = [];
                for (var id in $scope.selected) {
                    if ($scope.selected[id]) {
                        ids.push(id);
                    }
                }

                return _.filter($scope.values, function (value) {
                    return ids.indexOf("" + value[$scope.selectionColumn]) !== -1;
                })
            }

            $scope.isSelected = function () {
                for (var id in $scope.selected) {
                    if ($scope.selected[id]) {
                        return true;
                    }
                }

                return false;
            }

            $scope.openCalendar = function (dateField) {
                $scope.datePickerOpenStatus[dateField] = true;
            }

            $scope.isFilterActive = function () {
                return !angular.equals($scope.filter, $scope._filter);
            }

            $scope.init = function () {
                $scope.datePickerOpenStatus = {};
                $scope.filter = {};
                $scope.itemsPerPage = 10;
                $scope.totalItems = 0;
                $scope.dtInstance = {};
                $scope.page = 1;
                $scope.values = [];
                $scope.selected = {};
                $scope.selectAll = false;
                $scope.filtersDropdown = false;

                $scope.localSearch = $scope.descriptor.localSearch || $scope.descriptor.localSearch === undefined;
                $scope.enableSearch = $scope.descriptor.enableSearch || $scope.descriptor.enableSearch === undefined;
                $scope.params = $scope.descriptor.params ? $scope.descriptor.params : {};
                $scope.newAction = $scope.descriptor.newAction === undefined || $scope.descriptor.newAction;
                $scope.filterDescriptors = $scope.descriptor.filters ? $scope.descriptor.filters : [];
                $scope.visibleFilterDescriptors = $scope.getVisibleFilters();
                $scope.withSelectionColumn = $scope.descriptor.withSelectionColumn !== undefined && $scope.descriptor.withSelectionColumn;
                $scope.selectionColumn = $scope.descriptor.selectionColumn ? $scope.descriptor.selectionColumn : 'id';

                $scope.filter = $scope.initFilter();
                $scope._filter = angular.copy($scope.filter);

                $scope.dtOptions = $scope.getDtOptions();
                $scope.dtColumns = $scope.getDtColumns();
                $scope.dtColumns.push(...$scope.getDtColumnRowActions());

                $scope.updateDtColumns();
            }

            $scope.getVisibleFilters = function () {
                return _.filter($scope.filterDescriptors, function (item) {
                    return item.visible !== false;
                });
            }

            $scope.init();

            $scope.getRmDatePicker = function () {
                return _.find($scope.visibleFilterDescriptors, ['type', 'rm2-datepicker']);
            }
        }
    }
})();
