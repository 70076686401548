(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartDetailsController', ChartDetailsController);

    ChartDetailsController.$inject = ['$state', '$window', '$uibModal', 'toastr', 'CoreService',
        'fancyboxService', 'chart', 'Allergies', 'Diet', 'ACTIONS', 'access', 'Chart', '$rootScope',
        '$scope', 'Insurance', '$uibModalStack', 'PopupService', 'ChartPicture', 'ChartPictureLicense',
        '$location', 'params', 'TAB', 'ConfirmationService'];

    function ChartDetailsController($state, $window, $uibModal, toastr, CoreService,
                                    fancyboxService, chart, Allergies, Diet, ACTIONS, access, Chart, $rootScope,
                                    $scope, Insurance, $uibModalStack, PopupService, ChartPicture, ChartPictureLicense,
                                    $location, params, TAB, ConfirmationService) {
        var vm = this;

        vm.title = 'Patient Details';
        vm.floatingMenuStatus = false;
        vm.chart = chart;
        vm.chart.age = moment().diff(vm.chart.dateBirth, 'years');
        vm.chartSelected = {
            id: vm.chart.id
        };

        vm.access = access;
        vm.accessEditId = access[ACTIONS.ACTION_PATIENT_EDIT_ID] ? access[ACTIONS.ACTION_PATIENT_EDIT_ID].roles.join() : null;
        vm.accessEditInfo = access[ACTIONS.ACTION_PATIENT_EDIT_INFO] ? access[ACTIONS.ACTION_PATIENT_EDIT_INFO].roles.join() : null;
        vm.accessInsuranceView = access[ACTIONS.ACTION_PATIENT_INSURANCE_VIEW] ? access[ACTIONS.ACTION_PATIENT_INSURANCE_VIEW].roles.join() : null;
        vm.accessContactsView = access[ACTIONS.ACTION_PATIENT_CONTACTS_VIEW] ? access[ACTIONS.ACTION_PATIENT_CONTACTS_VIEW].roles.join() : null;
        vm.accessCareTeamView = access[ACTIONS.ACTION_PATIENT_CARE_TEAM_VIEW] ? access[ACTIONS.ACTION_PATIENT_CARE_TEAM_VIEW].roles.join() : null;
        vm.accessCareCoordinationView = access[ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW] ? access[ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW].roles.join() : null;
        vm.accessAllergiesView = access[ACTIONS.ACTION_PATIENT_ALLERGIES_VIEW] ? access[ACTIONS.ACTION_PATIENT_ALLERGIES_VIEW].roles.join() : null;
        vm.accessDietsView = access[ACTIONS.ACTION_PATIENT_DIETS_VIEW] ? access[ACTIONS.ACTION_PATIENT_DIETS_VIEW].roles.join() : null;
        vm.accessVitalsView = access[ACTIONS.ACTION_PATIENT_VITALS_VIEW] ? access[ACTIONS.ACTION_PATIENT_VITALS_VIEW].roles.join() : null;
        vm.accessGlucoseView = access[ACTIONS.ACTION_PATIENT_GLUCOSE_VIEW] ? access[ACTIONS.ACTION_PATIENT_GLUCOSE_VIEW].roles.join() : null;
        vm.accessWeightView = access[ACTIONS.ACTION_PATIENT_WEIGHT_VIEW] ? access[ACTIONS.ACTION_PATIENT_WEIGHT_VIEW].roles.join() : null;
        vm.accessAlcoholView = access[ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW] ? access[ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW].roles.join() : null;
        vm.accessDrugsView = access[ACTIONS.ACTION_PATIENT_DRUGS_VIEW] ? access[ACTIONS.ACTION_PATIENT_DRUGS_VIEW].roles.join() : null;
        vm.accessFileView = access[ACTIONS.ACTION_PATIENT_FILE_VIEW] ? access[ACTIONS.ACTION_PATIENT_FILE_VIEW].roles.join() : null;
        vm.accessChartNoteView = access[ACTIONS.ACTION_PATIENT_CHART_NOTE_VIEW] ? access[ACTIONS.ACTION_PATIENT_CHART_NOTE_VIEW].roles.join() : null;
        vm.accessConcurrentReviewView = access[ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW] ? access[ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW].roles.join() : null;
        vm.accessVerifytxView = access[ACTIONS.ACTION_PATIENT_VERIFYTX_VIEW] ? access[ACTIONS.ACTION_PATIENT_VERIFYTX_VIEW].roles.join() : null;
        vm.accessLevelCareHistory = access[ACTIONS.ACTION_PATIENT_CHART_LEVEL_CARE_HISTORY] ? access[ACTIONS.ACTION_PATIENT_CHART_LEVEL_CARE_HISTORY].roles.join() : null;
        vm.accessDischargeHistory = access[ACTIONS.ACTION_PATIENT_CHART_DISCHARGE_HISTORY] ? access[ACTIONS.ACTION_PATIENT_CHART_DISCHARGE_HISTORY].roles.join() : null;
        vm.accessTrackHistory = access[ACTIONS.ACTION_PATIENT_CHART_TRACK_HISTORY] ? access[ACTIONS.ACTION_PATIENT_CHART_TRACK_HISTORY].roles.join() : null;
        vm.accessDiagnosesView = access[ACTIONS.ACTION_PATIENT_DIAGNOSES_VIEW] ? access[ACTIONS.ACTION_PATIENT_DIAGNOSES_VIEW].roles.join() : null;
        vm.accessLabOrdersView = access[ACTIONS.ACTION_PATIENT_LAB_ORDERS_VIEW] ? access[ACTIONS.ACTION_PATIENT_LAB_ORDERS_VIEW].roles.join() : null;
        vm.accessELabsView = access[ACTIONS.ACTION_PATIENT_ELABS_VIEW] ? access[ACTIONS.ACTION_PATIENT_ELABS_VIEW].roles.join() : null;
        vm.accessGroupSessionView = access[ACTIONS.ACTION_PATIENT_GROUP_SESSION_VIEW] ? access[ACTIONS.ACTION_PATIENT_GROUP_SESSION_VIEW].roles.join() : null;
        vm.accessEvaluationView = access[ACTIONS.ACTION_PATIENT_EVALUATION_VIEW] ? access[ACTIONS.ACTION_PATIENT_EVALUATION_VIEW].roles.join() : null;
        vm.accessMedicationView = access[ACTIONS.ACTION_PATIENT_MEDICATION_VIEW] ? access[ACTIONS.ACTION_PATIENT_MEDICATION_VIEW].roles.join() : null;
        vm.active = 0;

        vm.activeTab = params.tabId ? parseInt(params.tabId) : TAB.BASIC_INFORMATION;
        vm.TAB = TAB;

        vm.edit = edit;
        vm.showBlueAlertPopup = showBlueAlertPopup;
        vm.changedChart = changedChart;
        vm.select = select;
        vm.addConcurrentReview = addConcurrentReview;
        vm.showLevelCareHistory = showLevelCareHistory;
        vm.showTrackHistory = showTrackHistory;
        vm.showDischargeDateHistory = showDischargeDateHistory;
        vm.showVerifyTxHistory = showVerifyTxHistory;
        vm.showConcurrentReviewHistory = showConcurrentReviewHistory;
        vm.verify = verify;
        vm.showVitalsHistory = showVitalsHistory;
        vm.showGlucoseHistory = showGlucoseHistory;
        vm.showWeightHistory = showWeightHistory;
        vm.showAlcoholHistory = showAlcoholHistory;
        vm.showDrugsHistory = showDrugsHistory;
        vm.showDiagnosesHistory = showDiagnosesHistory;
        vm.showAllergiesHistory = showAllergiesHistory;
        vm.showCareTeamHistory = showCareTeamHistory;
        vm.showDietsHistory = showDietsHistory;
        vm.showNotesHistory = showNotesHistory;
        vm.editImage = editImage;
        vm.editImageLicense = editImageLicense;
        vm.viewImageLicense = viewImageLicense;
        vm.checkField = checkField;

        function changedChart() {
            $state.go('chart-new', {id: vm.chartSelected.id});
        }

        Chart.previousCharts({id: vm.chart.id}, function (result) {
            var orderedResult = _.orderBy(result, function (item) {
                return moment(item.admissionDate)
            }, ['desc'])
            vm.previousCharts = _.map(orderedResult, function (item) {
                return {
                    id: item.id,
                    field: item.mrNo + ' - ' + (moment(item.admissionDate).format($rootScope.amDateFormat))
                }
            })
        });

        ChartPictureLicense.getOneByChart({id: vm.chart.id}, function (result) {
            vm.chartPictureLicense = result;
        });

        function viewImageLicense() {
            ChartPictureLicense.getCroppedImageByChart({id: vm.chart.id}, function (picture) {
                if (picture) {
                    fancyboxService.fancyboxPlus()({
                        'href': picture.image,
                        'title': 'License Picture',
                        'transitionIn': 'fade',
                        'transitionOut': 'elastic'
                    });
                }
            })
        }

        function editImage() {
            ChartPicture.getOriginalBase64ByChart({id: vm.chart.id}, function (result) {
                openCropImagePopup(result);
            }, function () {
                openCropImagePopup(null);
            });
        }

        function editImageLicense() {
            ChartPictureLicense.getOriginalBase64ByChart({id: vm.chart.id}, function (result) {
                openCropImageLicensePopup(result);
            }, function () {
                openCropImageLicensePopup(null);
            });
        }

        function openCropImagePopup(result) {
            PopupService.showCropImageChartPopup(result).result.then(function (error) {
            }, function (data) {
                if (data) {
                    ChartPicture.create({
                        chartId: vm.chart.id,
                        cropped: data.cropped,
                        image: data.image
                    }, function () {
                        $state.reload();
                    });
                }
            });
        }

        function openCropImageLicensePopup(result) {
            PopupService.showCropImageLicensePopup(result).result.then(function (error) {
            }, function (data) {
                if (data) {
                    ChartPictureLicense.create({
                        chartId: vm.chart.id,
                        cropped: data.cropped,
                        image: data.image
                    }, function () {
                        $state.reload();
                    });
                }
            });
        }

        function edit() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/chart/update/chart-dialog.html',
                controller: 'ChartDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: ['Chart', function (Chart) {
                        return Chart.get({id: chart.id}).$promise;
                    }]
                }
            }).result.then(function () {
                $state.reload();
            });
        }

        function showBlueAlertPopup() {
            $uibModal.open({
                templateUrl: 'app/entities/urgent-issues/urgent-issues-dialog.html',
                controller: 'UrgentIssuesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            status: null,
                            id: null,
                            chart: chart,
                            employees: [],
                            facility: CoreService.getCurrentFacility()
                        };
                    }
                }
            })
        }

        function select(index) {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartDetailActiveTab', index);

            $location.search('tabId', index);
        }

        function addConcurrentReview() {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartDetailAddConcurrentReview');
        }

        function verify() {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartDetailVerify');
        }

        function showLevelCareHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/type-level-care/chart/new/type-level-care-history.html',
                controller: 'TypeLevelCareHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showTrackHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/track/chart/new/track-history.html',
                controller: 'TrackHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showDischargeDateHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/chart/discharge-date-history/new/chart-discharge-date-history-new.html',
                controller: 'ChartDischargeDateHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showVerifyTxHistory() {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartDetailVerifyTxHistory');
        }

        function showConcurrentReviewHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/concurrent-reviews/chart-new/concurrent-reviews-history.html',
                controller: 'ConcurrentReviewsHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showCareTeamHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/chart-care-team/history/chart-care-team-history.html',
                controller: 'ChartCareTeamHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showDietsHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/diet/history/diet-history.html',
                controller: 'DietHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showNotesHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/chart-note/history/chart-note-history.html',
                controller: 'ChartNoteHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showVitalsHistory() {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartVitalsHistory');
        }

        function showGlucoseHistory() {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartGlucoseHistory');
        }

        function showWeightHistory() {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartWeightHistory');
        }

        function showAlcoholHistory() {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartAlcoholHistory');
        }

        function showDrugsHistory() {
            $uibModalStack.dismissAll();

            $rootScope.$broadcast('bluebookApp:chartDrugsHistory');
        }

        function showDiagnosesHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/diagnoses/history/diagnoses-history.html',
                controller: 'DiagnosesHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showAllergiesHistory() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/allergies/history/allergies-history.html',
                controller: 'AllergiesHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        var unsubscribe = $rootScope.$on('bluebookApp:closeChart', function (event, result) {
            $uibModalStack.dismissAll();
            $uibModal.open({
                templateUrl: 'app/entities/chart-status-history/chart-new/chart-status-histories-dialog-close-new.html',
                controller: 'ChartStatusHistoryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                resolve: {
                    entity: function () {
                        return {
                            status: 'CLOSED',
                            description: null,
                            date: null,
                            id: null
                        };
                    },
                    chart: vm.chart
                }
            }).result.then(function () {
                $state.reload();
            })
        });

        $scope.$on('$destroy', unsubscribe);

        var unsubscribe = $rootScope.$on('bluebookApp:setToCurrent', function (event, result) {
            $uibModalStack.dismissAll();
            $uibModal.open({
                templateUrl: 'app/entities/chart-status-history/chart-new/chart-status-histories-dialog-current-new.html',
                controller: 'ChartStatusHistoryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                resolve: {
                    entity: function () {
                        return {
                            status: 'CURRENT',
                            description: null,
                            date: null,
                            id: null
                        };
                    },
                    chart: vm.chart
                }
            }).result.then(function () {
                $state.reload();
            })
        });

        $scope.$on('$destroy', unsubscribe);

        var unsubscribe = $rootScope.$on('bluebookApp:reopenChart', function (event, result) {
            $uibModalStack.dismissAll();
            $uibModal.open({
                templateUrl: 'app/entities/chart-status-history/chart-new/chart-status-histories-dialog-reopen-new.html',
                controller: 'ChartStatusHistoryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                resolve: {
                    entity: function () {
                        return {
                            status: 'REOPEN',
                            description: null,
                            date: null,
                            id: null
                        };
                    },
                    chart: vm.chart
                }
            }).result.then(function () {
                $state.reload();
            })
        });

        $scope.$on('$destroy', unsubscribe);

        var unsubscribe = $rootScope.$on('bluebookApp:copyChart', function (event, result) {
            $uibModalStack.dismissAll();
            $uibModal.open({
                templateUrl: 'app/entities/chart/copy/chart-copy-dialog.html',
                controller: 'ChartCopyController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'md',
                resolve: {
                    chart: vm.chart
                }
            }).result.then(function (facility) {
                PopupService.showCreatePatientPopup(vm.chart, facility).result.then(function (result) {
                    CoreService.setCurrentFacility(facility);
                    $state.transitionTo('chart', {id: result.id}, {
                        location: true,
                        inherit: true,
                        notify: true,
                        reload: true
                    });
                });
            });
        });

        $scope.$on('$destroy', unsubscribe);

        var unsubscribe = $rootScope.$on('bluebookApp:chartExport', function (event, result) {
            $uibModalStack.dismissAll();
            $uibModal.open({
                templateUrl: 'app/entities/facesheet-tpl/chart-report/new/facesheet-tpl-report.html',
                controller: 'FacesheetTplReportController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                resolve: {
                    chart: vm.chart
                }
            });
        });

        $scope.$on('$destroy', unsubscribe);

        var unsubscribe = $rootScope.$on('bluebookApp:foodMemo', function (event, result) {
            $uibModalStack.dismissAll();
            ChartPicture.getCroppedImageByChart({id: chart.id}, function (result) {
                createFoodMemo(result.image);
            }, function () {
                createFoodMemo(CoreService.patientPictureDefault().picture);
            });

        });

        $scope.$on('$destroy', unsubscribe);

        var unsubscribe = $rootScope.$on('bluebookApp:blueAlerts', function (event, result) {
            $uibModalStack.dismissAll();
            $uibModal.open({
                templateUrl: 'app/entities/urgent-issues/chart/new/history/urgent-issues-history-dialog.html',
                controller: 'UrgentIssuesHistoryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        });

        $scope.$on('$destroy', unsubscribe);

        var unsubscribe = $rootScope.$on('bluebookApp:deleteChart', function (event, result) {
            $uibModalStack.dismissAll();
            del();
        });

        $scope.$on('$destroy', unsubscribe);

        function checkField(field) {
            return CoreService.enableByFacilityType(vm.chart.facility, field);
        }

        function createFoodMemo(pictureRef) {
            var dd = {
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        alignment: 'justify'
                    },
                    subheader: {
                        fontSize: 15,
                        bold: true
                    },
                    quote: {
                        italics: true
                    },
                    small: {
                        fontSize: 8
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };
            var header = {
                text: 'Food Memo',
                style: 'header'
            };
            var information = {
                text: 'Patient special dietary',
                style: 'subheader',
                alignment: 'center'
            };
            var personalInfo = {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    body: [[{
                        text: 'Name: ',
                        style: 'tableHeader'
                    }, vm.chart.lastName + ", " + vm.chart.firstName],
                        [{text: 'MR Number: ', style: 'tableHeader'}, vm.chart.mrNo]]
                }, layout: 'noBorders'
            };
            var image = {image: pictureRef, alignment: 'center', width: 450, height: 450};
            var foodAllergies = {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: []
                },
                alignment: 'center',
                layout: 'noBorders'
            };
            var activeDiets = {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: []
                },
                alignment: 'center',
                layout: 'noBorders'
            };
            dd.content.push(header);
            dd.content.push(information);
            dd.content.push(personalInfo);
            dd.content.push(image);

            Allergies.findAllAllergiesByChart({id: chart.id, allergenType: 'Food'}, function (result) {
                vm.allergies = result;
                foodAllergies.table.body.push([{
                    text: 'Food Allergies',
                    style: 'tableHeader',
                    decoration: 'underline'
                }]);
                for (var i = 0; i < vm.allergies.length; i++) {
                    foodAllergies.table.body.push([{text: vm.allergies[i].name}]);
                }
                if (vm.allergies.length) {
                    dd.content.push(foodAllergies);
                }

                Diet.findAllActiveDietsByChart({id: chart.id}, function (result) {
                    vm.diets = result;
                    activeDiets.table.body.push([{text: 'Diets', style: 'tableHeader', decoration: 'underline'}]);
                    for (var i = 0; i < vm.diets.length; i++) {
                        activeDiets.table.body.push([{text: vm.diets[i].typeFoodDiets.name}]);
                    }
                    if (vm.diets.length) {
                        dd.content.push(activeDiets);
                    }
                    if (vm.diets.length || vm.allergies.length) {
                        vm.pdf = pdfMake.createPdf(dd);
                        vm.pdf.print();
                    } else
                        alert("this patient does not have diet");
                });
            });
        }

        function del() {
            ConfirmationService.showDelete('Chart').result.then(function () {
                Chart.delete({id: vm.chart.id}, function () {
                    $state.go('charts');
                });
            })
        }
    }
})();
