(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GlucoseHistoryController', GlucoseHistoryController);

    GlucoseHistoryController.$inject = ['$compile', '$scope', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$filter',
        '$uibModalInstance', 'chart', 'Glucose', '$rootScope', '$uibModal', 'ConfirmationService'];

    function GlucoseHistoryController($compile, $scope, $q, DTColumnBuilder, DTOptionsBuilder, $filter,
                                      $uibModalInstance, chart, Glucose, $rootScope, $uibModal, ConfirmationService) {
        var vm = this;

        vm.glucoses = [];
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.chart = chart;

        vm.search = search;
        vm.edit = edit;
        vm.del = del;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Glucose.findAllByChart(
                {
                    id: vm.chart.id,
                    page: vm.page - 1,
                    size: vm.itemsPerPage
                }, function (result, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.glucoses = result;
                    defer.resolve(vm.glucoses);
                });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('').notVisible().renderWith(function (data, type, full) {
                return data ? moment(data.date).format("YYYYMMDD") : "";
            }),
            DTColumnBuilder.newColumn('date').withTitle('Date').renderWith(function (data, type, full) {
                return data ? moment(data).format($rootScope.amDateFormat) : "";
            }),
            DTColumnBuilder.newColumn('reading').withTitle('Reading'),
            DTColumnBuilder.newColumn(null).withTitle('Type of Check').renderWith(function (data, type, full) {
                return data.typeCheck ? data.typeCheck.name : '-';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Interventions').renderWith(function (data, type, full) {
                return data.glucoseInterventions ? _.map(data.glucoseInterventions, 'name').join(', ') : '-';
            }),
            DTColumnBuilder.newColumn('notes').withTitle('Notes').withOption('width', '250px'),
            DTColumnBuilder.newColumn('createdBy').withTitle('Created By'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="dt-btn warning" ng-click="vm.edit(' + data.id + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            stButtons += '<a class="dt-btn danger" ng-click="vm.del(' + data.id + ')">' +
                '   <i class="fa fa-trash"></i></a>';

            return stButtons;
        }


        function search() {
            vm.dtInstance.reloadData();
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/glucose/update/glucose-dialog.html',
                controller: 'GlucoseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    entity: ['Glucose', function (Glucose) {
                        return Glucose.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                search();
            }, function () {
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Glucose').result.then(function () {
                Glucose.delete({id: id}, function () {
                    search();
                });
            })
        }
    }
})();
